import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { TestCloudFunction } from "../../components/TestCloudFunctions";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "chrome-puppeteer"
    }}>{`Chrome Puppeteer`}</h1>
    <p>{`Chrome Puppeteer lets you run and control a browser in the cloud. You can do anything a user can do manually.`}</p>
    <p>{`Great for taking screenshots, scraping data, and manual testing.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://pptr.dev/"
      }}>{`Chrome Puppeteer Documentation`}</a>{` gives you full API docs for what's possible.`}</p>
    <h2 {...{
      "id": "load-a-website"
    }}>{`Load a website`}</h2>
    <p>{`Start with a lambda that runs chrome and loads a website.`}</p>
    <h3 {...{
      "id": "exercise"
    }}>{`Exercise`}</h3>
    <p>{`Move into `}<inlineCode parentName="p">{`exercise-4`}</inlineCode>{` from the `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises"
      }}>{`serverless-workshop-exercises GitHub repository`}</a>{`.`}</p>
    <p>{`I've preconfigured it with necessary dependencies:`}</p>
    <ul>
      <li parentName="ul">{`aws-lambda`}</li>
      <li parentName="ul">{`chrome-aws-lambda`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "mailto:puppeteer@3.1.0"
        }}>{`puppeteer@3.1.0`}</a></li>
    </ul>
    <p>{`Tell serverless to avoid packaging the entire browser.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# serverless.yml

package:
  exclude:
    - node_modules/puppeteer/.local-chromium/**
`}</code></pre>
    <p>{`Add your screenshot function to `}<inlineCode parentName="p">{`serverless.yml`}</inlineCode>{`. Use a GET.`}</p>
    <p>{`Make sure to specify a large `}<inlineCode parentName="p">{`memorysize:`}</inlineCode>{` (2536 is good) and long `}<inlineCode parentName="p">{`timeout:`}</inlineCode>{` (30 is max). Gives Chrome room to breathe :)`}</p>
    <p>{`Use the `}<inlineCode parentName="p">{`getChrome()`}</inlineCode>{` method from `}<inlineCode parentName="p">{`src/utils`}</inlineCode>{` to instantiate your browser.`}</p>
    <p>{`Open a new tab and load a page:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const page = await browser.newPage()
await page.goto(<your url>, {
  waitUntil: ["domcontentloaded", "networkidle2"],
})
`}</code></pre>
    <p>{`Grab the first `}<inlineCode parentName="p">{`H1`}</inlineCode>{` element and return its value.`}</p>
    <pre><code parentName="pre" {...{}}>{`const h1value = await page.$eval("h1", (el) => el.innerHTML);
`}</code></pre>
    <p>{`Try getting the URL from query params :)`}</p>
    <TestCloudFunction serviceName="serverless" urlPlaceholder="<domain>/take-screenshot" jsonPlaceholder="" mdxType="TestCloudFunction" />
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises/commit/0264e4efdc6a647c5690bb23be91f7c27a012a87"
      }}>{`https://github.com/Swizec/serverless-workshop-exercises/commit/0264e4efdc6a647c5690bb23be91f7c27a012a87`}</a></p>
    <h2 {...{
      "id": "take-a-screenshot"
    }}>{`Take a screenshot`}</h2>
    <p>{`A fun way to use Puppeteer is taking screenshots.`}</p>
    <h3 {...{
      "id": "exercise-1"
    }}>{`Exercise`}</h3>
    <p>{`Tell your API Gateway it's okay to serve binary files.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# serverless.yml
provider:
  # ...
  apiGateway:
    binaryMediaTypes:
      - "*/*"
`}</code></pre>
    <p>{`Get the first H1 element again and measure its size.`}</p>
    <p>{`Screenshots work on pixels, not the DOM. If you pick a large element like `}<inlineCode parentName="p">{`body`}</inlineCode>{` you might run into problems with screenshots being too large for Puppeteer to handle.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const element = await page.$("h1")
const boundingBox = await element.boundingBox()
`}</code></pre>
    <p>{`Take a screenshot with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const imagePath = \`/tmp/screenshot-\${new Date().getTime()}.png\`

await page.screenshot({
  path: imagePath,
  clip: boundingBox,
})

const data = fs.readFileSync(imagePath).toString("base64")
`}</code></pre>
    <p>{`Serve it back from your lambda with correct image headers and content encoding`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`return {
  statusCode: 200,
  headers: {
    "Content-Type": "image/png",
  },
  body: data,
  isBase64Encoded: true,
}
`}</code></pre>
    <p>{`Try this one in the browser :)`}</p>
    <h3 {...{
      "id": "solution-1"
    }}>{`Solution`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises/commit/15c27d6f491ef0ba6b2015d12085a35b0dacd713"
      }}>{`https://github.com/Swizec/serverless-workshop-exercises/commit/15c27d6f491ef0ba6b2015d12085a35b0dacd713`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      